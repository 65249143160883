<script>
import Layout from "../../layouts/main";
import HttpRequest from "@/http/HttpRequest";
import Preference from "@/data/Preference";

let httpRequest = new HttpRequest();
let preference = new Preference();

export default {
  data() {
    return {
      oauth_verifier: null,
      isLoading: false,
    };
  },

  components: {
    Layout,
  },

  methods: {
    requestTokenForJiraIntegration() {
      let _this = this;
      _this.isLoading = true;
      let userID = preference.getData(Preference.KEY_USER_ID);
      let accessToken = preference.getData(Preference.KEY_JIRA_ACCESS_TOKEN);
      let accessTokenSecret = preference.getData(Preference.KEY_JIRA_ACCESS_TOKEN_SECRET);
      let oathVerifier = preference.getData(Preference.KEY_JIRA_OAUTH_VERIFIER);
      if (accessToken && accessTokenSecret && oathVerifier) {
        return httpRequest
          .requestTokenForJiraIntegration(
            userID,
            accessToken,
            accessTokenSecret,
            oathVerifier
          )
          .then(function (response) {
            _this.isLoading = false;
            if (response.success) {
              _this.oauthToken = response.data.vendor_oauth.token;
              _this.oauthTokenSecret = response.data.vendor_oauth.token_secret;
              preference.setData(Preference.KEY_JIRA_ACCESS_TOKEN, _this.oauthToken);
              preference.setData(
                Preference.KEY_JIRA_ACCESS_TOKEN_SECRET,
                _this.oauthTokenSecret
              );

              _this
                .$swal({
                  text: "Jira integrated successfully!",
                })
                .then(() => {
                  location.replace(`/integration`);
                });
            } else {
              _this
                .$swal({
                  text: response.message,
                })
                .then(() => {
                  location.replace("/integration");
                });
            }
          });
      } else {
        _this
          .$swal({
            text: "Invalid Credentials!!!",
          })
          .then(() => {
            location.replace("/integration");
          });
      }
    },
  },
  async created() {
    this.oauth_verifier = this.$route.query.oauth_verifier;
    preference.setData(Preference.KEY_JIRA_OAUTH_VERIFIER, this.oauth_verifier);
    await this.requestTokenForJiraIntegration();
  },
};
</script>

<template>
  <Layout>
    <div class="row" v-if="isLoading">
      <div class="col-12 mb-4">
        <div class="card h-100">
          <div class="card-body">
            <div class="text-center mt-3 mb-3">
              <b-spinner class="align-middle"></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style scoped>
.customs-bg-color {
  background-color: #ff7c47 !important;
}
</style>
